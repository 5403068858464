import Crm from './Crm'
export default class Legal {
  id!: number
  member_number!: Crm
  court_name!: string
  file_number!: number
  procedure_type!: string
  date_registered!: Date
  observation!: string
  inputMemberNumber!: string
}
